body {
    font-family: 'Roboto-Mono', sans-serif;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar{
    width: 8px !important;
    cursor: pointer;
}
::-webkit-scrollbar-track {
    background: #EDF1FF;
}
::-webkit-scrollbar-thumb {
    background: #3EB1C8;
    border-radius: 10px !important;
}