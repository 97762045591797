.slick-prev, .slick-next {
  padding: 300px 220px !important;
  /* visibility: hidden !important; */
  z-index: 999;
}

.slick-prev::before, .slick-next::before{
  color: #00000000 !important;
}

.slick-list {
  padding: 65px 0 !important;
  padding-left: 21px !important;
}

.slick-track .slick-active {
  visibility: visible;
}

.slick-slide {
  visibility: hidden;
}

.slick-center div .tariffCard {
  transition: all ease 0.3s;
  background-color: #3EB1C8;
  transform: scale(1.1,1.2);
  color: #fff
}

.slick-center div .tariffCard .tariffCardBtn {
  background-color: #fff;
  color: #3EB1C8;
}

.slick-center div .tariffCard .tariffPeriod {
  background-color: #8BD0DE;
}
.slick-center div .tariffCard .tariffPeriod .tariffPeriodType {
  color: #fff;
}
.slick-center div .tariffCard .tariffPeriod .dynamicBtn {
  background-color: #3EB1C8;
}

@media only screen and (max-width: 1200px) {
  .slick-prev, .slick-next {
    padding: 300px 160px !important;
  }
}

@media only screen and (max-width: 992px) {

  .slick-prev, .slick-next {
    padding: 300px 80px !important;
  }
  .sliderWrap{
      display: none !important;
  }
  .slick-list {
    padding: 65px 0 !important;
    padding-left: 460px !important;
  }
}